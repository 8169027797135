<template>
  <div
    class="participants"
    :data-study="edcRole.studyId"
    :data-site="edcRole.siteId"
  >
    <PageHeader
      :title="siteName"
      :subtitle="subtitle"
    >
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="goToEnrollParticipant"
        >
          Add Participant
        </el-button>
      </template>
    </PageHeader>

    <div
      v-if="pendingParticipants.length"
      class="pending-participants-banner"
    >
      <img
        :src="participantImage"
        alt="A graphic representing a participant"
      >
      <div class="pending-participants-banner__content">
        <h3>Transitioning Participants</h3>
        <p>{{ pendingParticipantsText }}</p>
      </div>
      <el-button
        type="outline"
        class="review-participants-button"
        @click="navigateToPendingParticipants"
      >
        Review Participants
      </el-button>
    </div>

    <SearchFilters
      v-if="filters.length"
      :filters="filters"
      @filter-updated="updateSearchFilter"
    />

    <!-- show the SearchBox while loading to minimize layout flash. -->
    <SearchBox
      v-if="isLoading || hasParticipants"
      placeholder="Find a participant"
      @input="searchStringUpdate"
    />
    <EmptyState
      v-if="participantsError"
      title="Error Loading Data"
      subtitle="Try refreshing this page"
      image="error"
      :image-above-text="true"
      :display-in-table="true"
    >
      <el-button
        type="primary"
        class="refresh-page-button"
        @click="refreshPage"
      >
        Refresh Page
      </el-button>
    </EmptyState>
    <el-table
      v-else
      ref="participantsTable"
      v-loading="isLoading"
      class="participants-table clickable-rows"
      :class="{ 'no-results': !hasResults }"
      :data="participantsToDisplay"
      @sort-change="handleSortEvent"
      @row-click="row => goToParticipantDetail(row.id)"
    >
      <el-table-column
        label="Participant ID"
        prop="customerDefinedId"
        :resizable="false"
        sortable="custom"
        fixed
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <a
            class="participants__id-link"
            :data-test="`${getCurrentVisit(row)} ${getCurrentVisitStatus(row)} ${getParticipantStatus(row)}`"
            :aria-label="`Go to participant ${row.customerDefinedId}`"
            @click="goToParticipantDetail(row.id)"
          >
            {{ row.customerDefinedId }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        label="First Name"
        prop="firstName"
        :resizable="false"
        sortable="custom"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <strong>{{ row.firstName }}</strong>
        </template>
      </el-table-column>
      <el-table-column
        label="Last Name"
        prop="familyName"
        :resizable="false"
        sortable="custom"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <strong>{{ row.familyName }}</strong>
        </template>
      </el-table-column>
      <el-table-column
        label="Cohort"
        prop="cohort"
        :resizable="false"
        sortable="custom"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <strong v-if="row.cohortId">
            {{ getCohortName(row.cohortId) }}
          </strong>
        </template>
      </el-table-column>
      <el-table-column
        :label="namingColumnStatus"
        prop="status"
        :resizable="false"
        sortable="custom"
      >
        <template v-slot:header="{ column }">
          {{ column.label }}
          <Caret :order="column.order" />
        </template>
        <template v-slot="{ row }">
          <strong v-if="getParticipantStatus(row)">
            {{ getParticipantStatus(row) | capitalize }}
          </strong>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="showEmpyState">
      <EmptyState
        v-if="!isLoading"
        title="No Participants Found"
        image="participant"
        :image-above-text="true"
        :display-in-table="true"
      >
        <template slot="subtitle">
          <p>
            {{ hasParticipants ? 'Try another search term' : 'Add a participant to this site to get started' }}
            <a
              v-if="hasSearchFiltersApplied"
              @click="clearSearchFilters"
            >
              or clear applied filters
            </a>
          </p>
        </template>
      </EmptyState>
      <SkeletonTable v-else />
    </div>

    <Pagination
      v-if="hasParticipants && hasResults"
      :total="filteredParticipants.length"
      :page="pagination.page"
      :per-page="pagination.perPage"
      @pagechanged="handlePageChange"
      @perpage-changed="handlePerPageChange"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader/PageHeader'
import SearchBox from '@/components/SearchBox/SearchBox'
import EmptyState from '@/components/EmptyState/EmptyState'
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable'
import Caret from '@/components/Caret/Caret'
import Pagination from '@/components/Pagination/Pagination'
import SearchFilters from '@/components/SearchFilters/SearchFilters'
import searchFilterFunctionality from '@/components/SearchFilters/SearchFilterFunctionality'
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import GET_SITE_PARTICIPANTS_QUERY from '@/graphql/participants/GetSiteParticipantsQuery.graphql'
import GET_PENDING_PARTICIPANTS_QUERY from '@/graphql/participants/GetPendingParticipantsEDCQuery.graphql'
import GET_SITES_QUERY from '@/graphql/sites/GetSitesQuery.graphql'
import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import { ParticipantProtocolStatus, VisitStatus } from '@/utils/constants.js'
import { displayAbbreviatedDate } from '@/utils/date.js'
import { sortableCustomerDefinedId } from '@/utils/participant.js'
import { ascend, path, pathOr, prop, reverse, sort } from 'ramda'
import { logError } from '@/utils/logging'
import { ppmiClinicStudyName, Features } from '@/utils/constants'
import { isParticipantExcluded } from '@/ppmi/participants'
import participantImage from '@/assets/images/emptystate-participants.svg'

export default {
  components: {
    PageHeader,
    SearchBox,
    EmptyState,
    SkeletonTable,
    Caret,
    Pagination,
    SearchFilters
  },
  mixins: [searchFilterFunctionality],

  data () {
    return {
      participantImage,
      pendingParticipants: [],
      participantsError: false,
      cohorts: [],
      edcRole: {},
      siteName: 'Site',
      participants: [],
      searchString: '',
      pagination: {
        page: 1,
        perPage: 10
      },
      sortBy: {
        column: '',
        order: null
      },
      filters: []
    }
  },

  apollo: {
    user() {
      return {
        query: GET_USER_QUERY,
        update: data => data.getUser,
        result() {
          this.edcRole = this.user.edcRoles.find(role => role.studyName === ppmiClinicStudyName)
        },
        error (error) {
          logError(error, 'Participants.vue user query')
        }
      }
    },

    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        update: data => data.getStudyCohorts,
        variables() {
          return { studyId: this.edcRole.studyId }
        },
        error (error) {
          logError(error, 'Participants.vue cohorts query')
        },
        skip() {
          return !this.edcRole.studyId
        }
      }
    },

    /*
     * Get all participants associated with a site and study.
     * If this page is accessed directly and no study or site exists,
     * load ALL participants. This is for testing purposes only.
     */
    participants () {
      this.$store.dispatch('updateLoading', true)
      return {
        query: GET_SITE_PARTICIPANTS_QUERY,
        variables() {
          return {
            studyId: this.edcRole.studyId,
            siteId: this.edcRole.siteId
          }
        },
        update: data => {
          if (data.getParticipants) {
            return data.getParticipants
          }
          return []
        },
        result () {
          this.$store.dispatch('updateLoading', false)
        },
        error (error) {
          logError(error, 'Participants.vue get site participants query')
          this.participantsError = true
        }
      }
    },

    pendingParticipants() {
      return {
        query: GET_PENDING_PARTICIPANTS_QUERY,
        variables() {
          return {
            studyId: this.edcRole.studyId,
            siteId: this.edcRole.siteId
          }
        },
        update: data => data.getPendingParticipantsEDC,
        error (error) {
          logError(error, 'Participants.vue get pending participants query')
        }
      }
    },

    // TODO: Rather than load all sites again, a query for site by siteId would be ideal.
    siteName() {
      return {
        query: GET_SITES_QUERY,
        variables() {
          return {
            studyId: this.edcRole.studyId
          }
        },
        update: data => {
          const thisSite = data.getSites.filter(site => site.id === this.edcRole.siteId)
          return thisSite[0].name
        },
        error (error) {
          logError(error, 'Participants.vue sites query')
        }
      }
    }
  },

  computed: {
    pendingParticipantsText() {
      if (this.pendingParticipants.length === 1) {
        return '1 participant at your site is awaiting a decision to transition to the current study.'
      } else {
        return `${this.pendingParticipants.length} participants at your site ` +
          'are awaiting a decision to transition to the current study.'
      }
    },
    namingColumnStatus () {
      if (this.isVisitWindowsSupported) {
        return 'Clinical Status'
      }
      return 'Status'
    },
    namingColumnCurrentVisit () {
      if (this.isVisitWindowsSupported) {
        return 'Current Visit Window'
      }
      return 'Current Visit'
    },
    namingColumnNextVisit () {
      if (this.isVisitWindowsSupported) {
        return 'Next Visit Window'
      }
      return 'Next Visit'
    },
    /*
     * Filter the list of participants by the search string (if provided)
     */
    filteredParticipants() {
      return this.participants
        .filter(participant => {
          // check the customerDefinedId
          const matchesCDID = this.stringIncludes(participant.customerDefinedId, this.searchString)
          // check the participant's first name
          const matchesFirstName = this.stringIncludes(participant.firstName, this.searchString)
          // check the participant's last name
          const matchesLastName = this.stringIncludes(participant.familyName, this.searchString)
          return matchesCDID || matchesFirstName || matchesLastName
        })
        .filter(this.applySearchFilters)
    },

    /*
     * Sort the participants by active sort criteria (column/prop & direction)
     */
    sortedParticipants() {
      if (this.sortBy.column) {
        // handle special cases with custom sorting functions.
        let sortingFunction = ascend(prop(this.sortBy.column))
        switch (this.sortBy.column) {
          case 'cohort':
            sortingFunction = ascend(a => this.getCohortName(a.cohortId))
            break
          case 'status':
            sortingFunction = ascend(a => a.protocols[0].status)
            break
          case 'currentVisit':
            // Sort by visit position
            sortingFunction = ascend(pathOr(-1, ['currentVisit', 'visitTemplate', 'position']))
            break
          case 'nextVisit':
            sortingFunction = ascend(pathOr(-1, ['nextVisit', 'visitTemplate', 'position']))
            break
          case 'customerDefinedId':
            sortingFunction = sortableCustomerDefinedId
            break
        }
        // Now, sort based on the defined sorting function and direction
        const sortedParticipants = sort(sortingFunction, this.filteredParticipants)
        return this.sortBy.order === 'ascending'
          ? sortedParticipants
          : reverse(sortedParticipants)
      }
      return this.filteredParticipants
    },

    /*
     * Determine the participants to display within the table based on pagination values
     */
    participantsToDisplay() {
      // pagination calculation
      const begin = this.pagination.perPage * (this.pagination.page - 1)
      const end = this.pagination.perPage * this.pagination.page
      return this.sortedParticipants.slice(begin, end)
    },

    // TODO: update headings once we have designs
    subtitle() {
      return 'Site Participants'
    },

    isLoading() {
      return this.$apollo.queries.participants.loading
    },

    hasParticipants() {
      return this.participants.length > 0
    },

    hasResults() {
      return this.filteredParticipants.length > 0
    },

    showEmpyState() {
      return !this.participantsError && !this.hasResults
    },
    isVisitWindowsSupported() {
      return this.$store.getters.supportedFeatures.includes(Features.VISIT_WINDOWS)
    }
  },

  watch: {
    /*
     * Once we receive our list of participants, set up the available filters
     */
    participants() {
      this.generateFilterOptions()
    }
  },

  methods: {
    getParticipantStatus(participant) {
      let status = ''
      if (participant && participant.protocols && participant.protocols.length) {
        if (participant.protocols[0].status === ParticipantProtocolStatus.SCREEN_FAILED) {
          status = 'Screen Failed'
        } else {
          status = participant.protocols[0].status
        }
      }
      return status
    },
    refreshPage() {
      window.location.reload()
    },

    getCohortName(cohortId) {
      const cohort = this.cohorts.find(_cohort => _cohort.id === cohortId)
      return cohort ? cohort.name : ''
    },

    getCurrentVisit(participant) {
      if (!participant.currentVisitWindow) {
        return ''
      }
      const visitNames = participant.currentVisitWindow.visits.map(visit =>
        `${visit.protocolName} ${visit.visitTemplate.name}`
      )
      return visitNames.join('<br>')
    },

    getCurrentVisitStatus(participant) {
      const visitInstance = path(['currentVisit', 'visitInstance'], participant)
      if (!visitInstance) { return '' }
      return visitInstance.status === VisitStatus.NOT_COMPLETING ? 'Did Not Complete' : visitInstance.status
    },

    getNextVisit(participant) {
      // Do not display the next visit for excluded participants
      if (isParticipantExcluded(participant)) {
        return ('-')
      } else if (!participant.nextVisitWindow) {
        return ''
      } else {
        const visitNames = participant.nextVisitWindow.visits.map(visit =>
          `${visit.protocolName} ${visit.visitTemplate.name}`
        )
        return visitNames.join('<br>')
      }
    },

    /** getVisitDateRange
     * @param {*} participant
     * @param {string} position
     */
    getVisitDateRange(participant, position = 'currentVisitWindow') {
      const scheduleBaseDate = path(['protocols', 0, 'scheduleBaseDate'], participant)
      if (!scheduleBaseDate && participant[position]?.visits.length === 2) {
        return 'Screening & Baseline'
      }

      if (!participant[position]) {
        return ''
      } else {
        return displayAbbreviatedDate(participant[position]?.startDate) + ' - ' +
          displayAbbreviatedDate(participant[position]?.endDate)
      }
    },

    goToParticipantDetail(participantId) {
      this.$router.push({
        name: 'visitSchedule',
        params: {
          studyId: this.edcRole.studyId,
          siteId: this.edcRole.siteId,
          participantId: participantId
        }
      })
    },

    goToEnrollParticipant() {
      this.$router.push({
        name: 'enrollParticipant',
        params: {
          studyId: this.edcRole.studyId,
          siteId: this.edcRole.siteId
        }
      })
    },

    navigateToPendingParticipants() {
      this.$router.push({
        name: 'EDCPendingParticipants',
        params: {
          studyId: this.edcRole.studyId,
          siteId: this.edcRole.siteId
        }
      })
    },

    /*
     * Update the search string and reset to the first page.
     */
    searchStringUpdate(event) {
      this.searchString = event
      // reset to page 1
      this.pagination.page = 1
    },

    /*
     * React to sorting events on the Element UI table.
     * Update our stored sorting variables with changes.
     */
    handleSortEvent({ column, prop, order }) {
      if (!order) {
        // if order is null, reset to default
        this.sortBy.column = ''
      } else if (this.sortBy.column !== prop) {
        // update column to the currently selected column for sorting
        this.sortBy.column = prop
      }
      this.sortBy.order = order
    },

    /*
     * When a page is changed, update our query
     */
    handlePageChange(newPage) {
      this.pagination.page = newPage
    },

    /*
     * When the perPage value is modified, update our query
     */
    handlePerPageChange(newPerPage) {
      this.pagination.perPage = newPerPage
      this.handlePageChange(1) // reset to page 1
    },

    /*
     * Utility class for testing whether a string includes a substring.
     * This class transforms the two strings to lowercase before making the comparison.
     */
    stringIncludes(testString, substring) {
      return testString.toLowerCase().includes(substring.toLowerCase())
    },

    /*
     * Generate a cohort, status and current visit filter from the participants returned.
     * The filter is added with unique options only, and if there exists more than 1 possible selection.
     */
    generateFilterOptions() {
      const availableFilters = []

      // Create an array of all unique cohorts
      // A Set object lets you store unique values of any type, it is convenient for storing unique values, cohorts.
      const uniqueCohorts = Array.from(new Set(this.participants.map(participant => participant.cohortId)))
      if (uniqueCohorts.length > 1) {
        // map the unique statuses into option objects
        const cohortOptions = this.buildOptions(uniqueCohorts, (a) => this.getCohortName(a))
        cohortOptions.unshift({ name: 'All Cohorts', value: '' })
        // add a filter using the available options
        availableFilters.push(this.buildFilter('Cohort', 'cohortId', cohortOptions))
      }

      const uniqueStatuses = Array.from(new Set(this.participants.map(participant => participant.protocols[0].status)))
      if (uniqueStatuses.length > 1) {
        const statusOptions = this.buildOptions(uniqueStatuses)
        statusOptions.unshift({ name: 'All Statuses', value: '' })
        availableFilters.push(this.buildFilter('Status', 'status', statusOptions))
      }

      const uniqueCurrentVisits = Array.from(new Set(
        this.participants
          .filter(participant => participant.currentVisit) // filter out any undefined values
          .map(participant => participant.currentVisit.name)
      ))
      if (uniqueCurrentVisits.length > 1) {
        const currentVisitOptions = this.buildOptions(uniqueCurrentVisits)
        currentVisitOptions.unshift({ name: 'All Visits', value: '' })
        availableFilters.push(this.buildFilter('Current Visit', 'currentVisit', currentVisitOptions))
      }

      this.filters = availableFilters
    },

    /*
     * Using the selected filter values, filter the list of participants.
     */
    applySearchFilters(participant) {
      // reformat the participant to make it easier to filter
      participant = {
        ...participant,
        ...participant.protocols[0],
        currentVisit: participant.currentVisit ? participant.currentVisit.name : ''
      }
      let includeParticipant = true
      this.filters.forEach(searchFilter => {
        if (includeParticipant !== false && searchFilter.selected.value) {
          if (participant[searchFilter.prop] !== searchFilter.selected.value) {
            includeParticipant = false
          }
        }
      })
      return includeParticipant
    }
  }
}
</script>

<style lang="scss">
.participants {
  text-align: initial;

  .pending-participants-banner {
    color: $black;
    height: 7.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    border-bottom: 1px solid $cortex;

    img {
      width: 4rem;
      height: 4rem;
      margin: 1rem;
    }

    h3 {
      margin-bottom: .5rem;
      @include text-style('title', 'medium', 'medium');
    }

    p {
      margin-top: .5rem;
    }

    .el-button {
      margin-left: 2.5rem;
    }

    &__content {
      flex: 1;
    }
  }

  &__id-link {
    color: $dopastat;
    text-decoration: none;
    @include text-weight('medium');
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  &__add-participant {
    background-color: $dopamine;
  }

  .participants-table {
    border: 1px solid $axon;
    border-top: none;

    td {
      cursor: pointer;
    }

    .el-dropdown {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
    .el-button--menu {
      height: 100%;
    }

    &.no-results {
      border-bottom: none;

      .el-table__body-wrapper {
        display: none;
      }
    }
  }

  th:not(:first-child) {
    border-right: none;
  }

  td:last-of-type {
    position: relative;
    overflow: hidden;
  }

  .cell {
    line-height: 1.2;

    // display these elements as blocks so that they each appear on their own line.
    .multiline,
    strong {
      text-align: left;
      display: block;
    }

    strong {
      @include text-weight('medium');
      margin-bottom: .25rem;
    }

    .subtext {
      color: $hillcock;
      @include text-style('interface', 'extra-small', 'regular');
    }
    .subtext.sub-study {
      display: block;
    }
  }
}

// Table style overrides
#app {
  .participants-table {
    th {
      text-align: left;
    }
  }
}
</style>
